// thanks https://stackoverflow.com/questions/9899372/pure-javascript-equivalent-of-jquerys-ready-how-to-call-a-function-when-t
// 
function r(f){/in/.test(document.readyState)?setTimeout('r('+f+')',9):f()}
// use like
r(function(){
	var gliders = document.querySelectorAll('[data-slider]');

	//Auto-clamp based on a fixed element height
	//$clamp($('.clamp')[0], {clamp: 5});

	$('[data-readmore]').on('click', function(){
		$(this).prev().toggleClass('visible');
		$(this).remove();
		return false;
	});

	if($('#propertyslider').length) {
		new Glide('#propertyslider', {
		  type: 'carousel',
		  perView: 5,
		  focusAt: 'center',
		  breakpoints: {
		    800: {
		      perView: 2
		    },
		    480: {
		      perView: 1
		    }
		  }
		}).mount();
	}
	

	for(var i = 0; i < gliders.length; i++)  {
	//gliders.forEach(function(g) {
	
		var g = gliders[i];

		//var counter = g.querySelectorAll('[data-glide-counter]');
		var $counter = $('[data-glide-counter]');

		var perView = $(g).data('per-view');
		
		const glide = new Glide(g, {
	    	animationDuration: 500,
	    	rewindDuration: 500,
	    	animationTimingFunc: 'ease-in-out',
	    	perView: perView ? perView : 1,
	    	breakpoints: {
	    		640: {
	    			perView: 1
	    		},
	    		1020: {
	    			perView: perView ? (perView > 3 ? 3 : perView) : 1
	    		}
	    	}
	    }).mount();

	    
		//console.log(counter);
		if($counter.length) {
		    glide.on('move', function() {
		    	$counter.html(glide.index + 1);
	    	});
		}
	}

	var disable = function() {
		return true;
	}

	var dateFrom = flatpickr('[name=from][type=date]', {
		dateFormat: "Y-m-d",
		altFormat: "Y/m/d",
		altInput: true,
		minDate: "today",
		disable: [{from: false, to: new Date()}],
		onChange: function(d, s, i) {
			if(d.length) {
				var date = d[0];
				//console.log(date);
				dateTo.set("disable", [{from: '1900-01-01', to: date}]);
				dateTo.open();
				//console.log(date.getDate() + 2);
				date.setDate(date.getDate() + 2);
				dateTo.setDate(date);
				dateFrom.close();

			} else {
				dateTo.set('disable', [disable]);
			}
		}
	});

	var dateTo = flatpickr('[name=to][type=date]', {
		dateFormat: "Y-m-d",
		altFormat: "Y/m/d",
		altInput: true,
		minDate: "today",
		defaultDate: new Date(),
		disable: [disable]
	});



	// Sticky search bar
	var search = document.querySelectorAll('.l-search')[0]
	// disable
	//search = false;
	if(search) {
		
		var searchOffset = search.offsetTop;

		var stickySearch = function() {
			let left = 0;
			if(window.innerWidth >= 980 && window.scrollY > searchOffset - 20) {
				search.classList.add('l-search--fixed');
				left = ($(window).width() - $(search).width()) / 2;
				$(search).css('left', left);
			} else {
				search.classList.remove('l-search--fixed');
				$(search).css('left', 'auto');
			}
		};

		window.onscroll = stickySearch;
		window.onresize = stickySearch;
		stickySearch();

	}
    

    // Allow "full area" click on cards
    // Using jQuery for development speed, not strictly necessary though
    $('[data-clicker]').each(function(){
    	//var $buttons = $('.m-button', $(this));
    	var $target = $(this).parents('[data-click-target]');
    	var $link = $(this);
    	if($target.length) {
	    	$target.css('cursor', 'pointer');
	    	console.log($target);
	    	$target.on('click', function(e){
	    		//if(e.target !== e.currentTarget) return;
	    		var url = $link.attr('href');
	    		window.location.href = url;
	    		return false;
	    	});
	    	/*if($buttons.length == 1) {
	    		$buttons.each(function(){    			
	    			var $button = $(this);
	    			
	    			$card.on('click', function(e){
	    				if(e.target !== e.currentTarget) return;
	    				$button.click();
	    			});

	    		});
	    	}*/
	    }
    });

    // background on mobile toggle
    $('#headertoggle').on('click', function(){
    	var $tog = $(this);
    	setTimeout(function(){
    		if($tog.is(':checked')) {
    			$('body').addClass('blackout');
    		} else {
    			$('body').removeClass('blackout');
    		}
    	}, 0);
    });
    
}); 